import React from 'react'
import CreateHead from "@components/CreateHead"
import Container from "@material-ui/core/Container"
import {makeStyles, withStyles} from "@material-ui/core/styles"
import Logo from "@components/Logo"
import {setColors} from "@theme/colors"
import {toRem} from "@utils/mixins"
import styled from "@emotion/styled"
import Button from "@material-ui/core/Button"
import {Box} from '@material-ui/core'
import Grid from "@material-ui/core/Grid"

const Headline = styled.h1`
   font-size: ${toRem(34)};
   color: ${setColors.black.full};
   line-height: ${toRem(36)};
   font-weight: 400;
   margin: 0 0 ${toRem(25)} 0;
   color: ${setColors.blue};

   @media (min-width: 1921px) {
     font-size: calc(2vw + 10px);
     margin: 0 0 2vw 0;
   }
 `
const Text = styled.div`
   font-size: ${toRem(16)};
   color: ${setColors.black.full};
   font-weight: 400;
   margin: 0 0 ${toRem(15)} 0;
   text-transform: none;
   line-height: ${toRem(24)};

   @media (min-width: 1921px) {
     font-size: calc(0.6vw + 10px);
     line-height: calc(0.7vw + 13px);
   }
   p.emphasis {
     color: ${setColors.blue};
   }

   p.updateNote {
     margin-top: ${toRem(60)};
     margin-bottom: ${toRem(40)};
     color: ${setColors.black.alpha};
   }
 `

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: toRem(20),
        paddingLeft: toRem(40),
        paddingRight: toRem(40),
        /* eslint-disable */
        ["@media(min-width: 1921px)"]: {
            minWidth: "60%",
        },
        /* eslint-enable */
    },
    backBtnWrapper: {
        display: "flex",
        justifyContent: "flex-end",
    },
}))


const About = () => {
    const classes = useStyles()

    const StyledButton = withStyles(theme => ({
        root: {
            borderRadius: 0,
            border: 0,
            color: setColors.blue,
            height: 47,
            boxShadow: "none",
            fontSize: toRem(14),
            background: "transparent",
            textAlign: "right",
            /* eslint-disable */
            ["@media(min-width: 1921px)"]: {
                marginTop: "1vw",
                fontSize: "calc(0.5vw + 10px)",
            },
            /* eslint-enable */

            "&:hover": {
                background: "transparent",
                color: setColors.blue,
                opacity: "0.8",
                boxShadow: "none",
            },
            "&:click": {
                background: "transparent",
            },
            "&:active": {
                background: "transparent",
            },
            display: "block",
            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },
        label: {
            textTransform: "uppercase",
        },
    }))(Button)

    const StyledIcon = withStyles(theme => ({
        root: {
            borderRadius: 0,
            border: 0,
            boxShadow: "none",
            background: "transparent",
            display: "none",
            [theme.breakpoints.down("xs")]: {
                display: "block",
            },
            textAlign: "right",
            "&:hover": {
                background: "transparent",
                color: setColors.blue,
                opacity: "0.8",
                boxShadow: "none",
            },
            "&:click": {
                background: "transparent",
            },
            "&:active": {
                background: "transparent",
            },
        },
        label: {
            textTransform: "uppercase",
        },
    }))(Button)

    return (
        <>
            <CreateHead title={"Welcome to Medtech"}/>
            <Logo
                background={setColors.grey.medium}
                laptop={182}
                desktop={182}
                big={300}
            />
            <Container maxWidth="md" className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box className={classes.backBtnWrapper}>
                            <StyledButton
                                variant="text"
                                size="large"
                                color="transparent"
                                href="/"
                                disableRipple={true}
                            >
                                back to homepage
                            </StyledButton>
                            <StyledIcon
                                variant="text"
                                size="large"
                                color="transparent"
                                href="/"
                                disableRipple={true}
                            >
                                <img src="images/arrow-back.png" alt="Back"/>
                            </StyledIcon>
                        </Box>
                        <Headline>Our story</Headline>
                        <Text>
                            <p>
                                Like many good ideas, the one which gave birth to the MedTech
                                project developed over time and came together over a multitude of
                                coffee-fuelled brainstorming sessions, lunches and dinners with
                                good friends and family.
                            </p>
                            <p className="emphasis">Medically driven</p>
                            <p>
                                The concept at the heart of the project is firmly built on medical foundations and finds
                                its origins in the work and experiences of Dr. Zach Dovey, a consultant urological
                                surgeon specializing in state-of-the-art robotic cancer surgery and prostate cancer
                                research.
                            </p>
                            <p>
                                Zach trained in Urology in the UK and South Africa, followed by Fellowship training in
                                Robotic Urological Cancer Surgery in France, the UK and the USA.
                            </p>
                            <p>
                                In addition to his specific surgical skills, Zach has responsibility for a patient’s
                                care from initial diagnosis right through treatment, and often for many years
                                afterwards.
                            </p>
                            <p>
                                He quickly observed that his patients would have benefited from both information and
                                support over and above the surgical, radiological and oncological expertise which he and
                                the medical team were providing.
                            </p>
                            <p>
                                Treating cancer patients every day, friendships naturally developed, and he saw
                                first-hand the shock, fear and distress suffered by patients, close family and friends.
                                He became determined to find additional ways to help and support them through this
                                traumatic period of their lives.
                            </p>
                            <p className="emphasis">The benefits of sharing</p>
                            <p>
                                Along with actively incorporating a more holistic and inclusive
                                approach to all types of cancer treatment, Zach concluded that
                                many of his patients and supporting family and friends would
                                further benefit from more detailed and specific background
                                information relating to their disease, its treatment and the
                                subsequent consequences and effects.
                            </p>
                            <p>
                                He discussed his thoughts with several patients who had become
                                friends and rapidly concluded that high on most of their wish
                                lists was a desire to exchange experiences with others who had
                                gone through exactly what they themselves were facing and who had
                                successfully come out the other side.
                            </p>
                            <p>
                                There are, of course, many patients willing to share their
                                experiences with others, but it became clear that the more similar
                                patients were, in terms of their exact stage of cancer and
                                treatment regime, the better the connection and the more benefit
                                they derived from the exchange.
                            </p>
                            <p className="emphasis">A unique approach</p>
                            <p>
                                With this in mind, Zach set out to scientifically ‘match’ his
                                patients with each other and identified more than 100 possible
                                data points that clinicians use to classify the disease, its
                                treatment and side effects.
                            </p>
                            <p>
                                Using this data, he then designed a complex algorithm which
                                processes the thousands of possible data point combinations and
                                matches clinically similar patients to others, at similar points
                                in their treatment.
                            </p>
                            <p>
                                And the analysis didn’t stop there. Some treatments can, for
                                example, have an effect far more profound in one patient than
                                another, so, using his more than 20 years of clinical experience,
                                Zach went on to fine-tune the process further, to take these
                                differences into account.
                            </p>
                            <p>
                                The resulting algorithm is at the heart of the MedTech community
                                and avoids the ‘looking for a needle in a haystack’ approach of
                                most support groups today.
                            </p>
                            <p className="emphasis">How you can help</p>
                            <p>
                                Whilst the diagnostic, treatment and general medical data points
                                of prostate cancer are clearly defined, human hopes, fears and
                                expectations are infinitely variable. We therefore wanted to
                                canvas the opinions of patients, survivors and supporting family
                                members in order to get a real understanding of what they would
                                look for by way of reassurance, help, assistance and comfort in
                                the community which we envisage.
                            </p>
                            <p>
                                To this end, in collaboration with our medical team, past
                                sufferers and a psychologist, we have developed a detailed survey
                                which will help us to quantify expectations and enable us to
                                ensure our community is as directly relevant and practically
                                supportive as possible.
                            </p>
                            <p>
                                If you haven’t already done so, please take the survey today. To
                                show our appreciation for your help, we are offering everyone who
                                completes it a complimentary family membership to the community
                                when it goes live later this year.
                            </p>
                            <p className="updateNote">Last update on 19 October 2021</p>
                        </Text>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
export default About
